/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'dompurify';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getChatRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import linkify from '../../../Utils/HyperLinkUtils';
import './ChatPane.scss';
import messages from '../../Placeholders/PlaceholdersMessages';

export const getSenderOrReceiversName = (isByBot, CreatedByName) => {
  if (isByBot) return 'LeoBot';
  let senderOrReceiversName = CreatedByName?.split(' ')[0];

  if (senderOrReceiversName && senderOrReceiversName.length > 23) {
    senderOrReceiversName = `${senderOrReceiversName.substring(0, 20)}...`;
  }

  return senderOrReceiversName;
};
export default class ChatPane extends React.Component {
  static MessageBubble = ({
    message,
    align,
    timestamp,
    className,
    type,
    messageType,
    DeliveryStatus,
    readOnly,
    RequestStatus,
    CreatedByName,
    isByBot,
  }) => {
    const senderOrReceiversName = getSenderOrReceiversName(isByBot, CreatedByName);
    const isPending = DeliveryStatus === 'Pending';
    const finalMessage = readOnly ? message : linkify(message);
    const showDeliveryStatus = className === 'bot-message' && messageType === 'Chat';
    if (type === 'title')
      return (
        <div className="chat-pane-title-wrapper">
          <span className="chat-pane-title"> {message} </span>
        </div>
      );

    if (align === 'right')
      return (
        <div className={`connect-message-bubble-wrapper ${align}`}>
          <div
            className={`connect-message-bubble ${align} ${className} ${isPending ? 'pending' : ''}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
            }}
          ></div>
          {DeliveryStatus === 'Pending' || RequestStatus === 'Pending' ? (
            <div className={`connect-message-bubble-time ${align}`}>
              {senderOrReceiversName} | {getChatRelativeTime(timestamp)} &nbsp;
              {!showDeliveryStatus && (
                <>
                  <Icon type="clock-circle" theme="filled" className="warning-icon" />
                  <span style={{ color: '#faad14', fontSize: '14px' }}>
                    <FormattedMessage {...messages.pendingLabel} />
                  </span>
                </>
              )}
            </div>
          ) : null}
          {DeliveryStatus === 'Success' ? (
            <div className={`connect-message-bubble-time ${align}`}>
              {senderOrReceiversName} | {getChatRelativeTime(timestamp)} &nbsp;
              {!showDeliveryStatus && (
                <>
                  <Icon
                    type="check-circle"
                    className="authentication-check-circle"
                    theme="filled"
                    style={{ fontSize: '14px' }}
                  />{' '}
                  &nbsp;
                  <span style={{ color: ' #52c41a', fontSize: '14px' }}>
                    <FormattedMessage {...messages.deliveredLabel} />
                  </span>{' '}
                </>
              )}
            </div>
          ) : null}
          {DeliveryStatus === 'Failed' || RequestStatus === 'Failed' || RequestStatus === 'Skipped' ? (
            <div className={`connect-message-bubble-time ${align} failed`}>
              {senderOrReceiversName} | {getChatRelativeTime(timestamp)} &nbsp;
              {!showDeliveryStatus && (
                <>
                  <Icon type="exclamation-circle" style={{ color: ' #eb4334' }} />{' '}
                  <span style={{ color: ' #eb4334', fontSize: '14px' }}>
                    <FormattedMessage {...messages.notDeliveredLabel} />
                  </span>{' '}
                </>
              )}
            </div>
          ) : null}
          {!RequestStatus ? (
            <div className={`connect-message-bubble-time ${align} failed`}>
              {' '}
              {senderOrReceiversName} | {getChatRelativeTime(timestamp)}
            </div>
          ) : null}
        </div>
      );
    return (
      <div className={`connect-message-bubble-wrapper ${align}`}>
        <div
          className={`connect-message-bubble ${align} ${className} ${isPending ? 'pending' : ''}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
          }}
        ></div>
        <div className={`connect-message-bubble-time ${align}`}>
          {' '}
          {senderOrReceiversName} | {getChatRelativeTime(timestamp)}
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);
    this.messagesWrapper = React.createRef();
    this.scrollChatToBottom = this.scrollChatToBottom.bind(this);
  }

  componentDidUpdate() {
    this.scrollChatToBottom();
  }

  scrollChatToBottom() {
    const { isCandidate360View, inputFieldRef } = this.props;
    if (isCandidate360View && inputFieldRef?.current) {
      setTimeout(() => inputFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 0);
    } else {
      this.messagesWrapper.current.scrollTop = this.messagesWrapper.current.scrollHeight;
    }
  }

  render() {
    const { children, className } = this.props;
    return (
      <div ref={this.messagesWrapper} className={`connect-message-window-flex-items messages-wrapper ${className}`}>
        <div className="inner-messages-wrapper">{children}</div>
      </div>
    );
  }
}

// const { MessageBubble } = ChatPane;
// ChatPane.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.instanceOf(MessageBubble), PropTypes.arrayOf(PropTypes.instanceOf(MessageBubble))]).isRequired
// }
